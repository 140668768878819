<template>
  <header role="banner">
    <h1 v-if="$route.path == '/'" class="site-name">
      <img loading="lazy" alt="Assistants maternels" :src="`${publicPath}titre-site.svg`" width="168" height="17" />
      <span class="site-slogan">Trouvez facilement votre assistant maternel</span>
    </h1>
    <div v-else class="site-name">
      <router-link title="Retourner à l’accueil" to="/">
        <img loading="lazy" alt="Assistants maternels" :src="`${publicPath}titre-site.svg`" width="168" height="17" />
      </router-link>
      <span class="site-slogan">Trouvez facilement votre assistant maternel</span>
    </div>
    
    <nav id="site-nav" class="site-nav" role="navigation" aria-label="Menu principal" :class="isOpened ? 'js-open' : ''">
      
      <button id="site-nav-button" class="site-nav-button" type="button" aria-label="Ouvrir / Fermer la navigation principale" aria-controls="main-nav" @click="toggleMainNav()" :aria-expanded="isOpened ? 'true' : 'false'">
        <span aria-hidden="true"></span>
      </button>
      
      <ul class="site-nav-items">
        <li :class="{ 'is-active': $route.path == '/assistant-maternel' }" @click="toggleMainNav()">
          <span v-if="$route.path == '/assistant-maternel'"><span class="sr-only">Rubrique active</span></span>
          <router-link :to="{ name: 'assistant-maternel' }">Assistant maternel</router-link>
        </li>
        <li :class="{ 'is-active': $route.path == '/parent' }" @click="toggleMainNav()">
          <span v-if="$route.path == '/parent'"><span class="sr-only">Rubrique active</span></span>
          <router-link :to="{ name: 'parent' }">Parent</router-link>
        </li>
        <li :class="{ 'is-active': $route.path == '/collectivite' }" @click="toggleMainNav()">
          <span v-if="$route.path == '/collectivite'"><span class="sr-only">Rubrique active</span></span>
          <router-link :to="{ name: 'collectivite' }">Collectivité</router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      isOpened: false,
      isInitiallyVisible: false,
    }
  },
  mounted() {
    if (!this.isMobile()) {
      this.isInitiallyVisible = true
    }
  },
  methods: {
    toggleMainNav: function () {
      if (this.isOpened) {
        this.isOpened = false;
      } else {
        this.isOpened = true;
        this.isInitiallyVisible = true;
      }
      
      if(this.getWindowWidth() < 992) {
        document.body.classList.toggle('js-overflow-hidden');
      }
    }
  },
}
</script>

<style scoped>
@media (min-width: 768px) {
  #site-container > header {
    grid-template-rows: auto;
    justify-content: left;
    width: 100%;
    padding: 0;
    margin-left: 0;
    height: auto;
  }
  h1.site-name {
    width: fit-content;
  }
  .site-name img {
    max-width: 80%;
    height: auto;
  }
  
  .site-nav.js-open .site-nav-items {
    margin-top: inherit;
  }
}

@media (min-width: 992px) {
  #site-container > header {
    height: 210px;
  }
}
</style>