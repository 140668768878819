export const routes = [
  {
    path: '/',
    name: 'accueil',
    meta: {
      title: 'Trouver un ou une assistante maternelle en Maine-et-Loire (49)',
      description: 'Toutes les informations pour les parents et les assistants maternels dans le Maine-et-Loire comme trouver une assistante maternelle ou devenir assistante maternelle.'
    },
    component: () => import('../views/Accueil.vue')
  },
  {
    path: '/plan-du-site',
    name: 'plan-du-site',
    meta: {
      title: 'Plan du site',
      description: 'Retrouvez toutes les pages du site assmat.maine-et-loire.fr.'
    },
    component: () => import('../views/PlanDuSite.vue')
  },
  {
    path: '/mentions-legales',
    name: 'mentions-legales',
    meta: {
      title: 'Mentions légales',
      description: 'Les crédits et mentions légales du site assmat.maine-et-loire.fr.'
    },
    component: () => import('../views/MentionsLegales.vue')
  },
  {
    path: '/parent',
    name: 'parent',
    meta: {
      title: 'Parent',
      description: 'Les informations et ressources à destination des parents pour devenir employeur d’une assistante maternelle en Maine-et-Loire.'
    },
    component: () => import('../views/Parent.vue')
  },
  {
    path: '/assistant-maternel',
    name: 'assistant-maternel',
    meta: {
      title: 'Assistant maternel',
      description: 'Le métier d’assistant maternel, le cadre légal et toutes les informations pour assurer l’accueil d’un enfant au quotidien en tant que professionnel.'
    },
    component: () => import('../views/AssistantMaternel.vue')
  },
  {
    path: '/Collectivite',
    name: 'collectivite',
    meta: {
      title: 'Collectivité',
      description: 'Retrouvez, par commune, les listes des assistants maternels agréés de Maine-et-Loire et leurs coordonnées.'
    },
    component: () => import('../views/Collectivite.vue')
  },
  {
    path: '/recherche',
    name: 'recherche',
    meta: {
      title: 'Trouvez un assistant maternel !',
      description: 'Recherchez votre future assistante maternelle à Angers et dans le Maine-et-Loire à l’aide des filtres et trouvez celle qui vous correspond.'
    },
    component: () => import('../views/Recherche.vue')
  },
  {
    path: '/ma-selection',
    name: 'selection',
    meta: {
      title: 'Ma sélection',
      description: 'Retrouvez la liste des assistants maternels que vous avez sélectionnés dans le Maine-et-Loire.'
    },
    component: () => import('../views/Selection.vue')
  },
  { 
    path: "*",
    name: '404',
    meta: {
      title: 'Page non trouvée',
      description: 'La page que vous cherchez sur assmat.maine-et-loire.fr n’existe pas ou a été supprimée.'
    },
    component: () => import('../views/404.vue')
  },
  {
    path: '/assmat/:userId',
    name: 'assmat',
    meta: {
      title: 'Fiche assmat',
      sitemap: {
        ignoreRoute: true
      },
      description: 'Géolocalisez votre future assistante maternelle avec sa présentation, ses expériences, ses qualifications, ses coordonnées et ses disponibilités.'
    },
    component: () => import('../views/FicheAssmat.vue'),
    props: true
  },
  {
    path: '/accessibilite',
    name: 'accessibilite',
    meta: {
      title: 'Accessibilité et qualité web',
      description: 'Accessibilité web et qualité du site assmat.maine-et-loire.fr du Département de Maine-et-Loire.'
    },
    component: () => import('../views/Accessibilite.vue')
  },
  {
    path: '/donnees-personnelles',
    name: 'donnees-personnelles',
    meta: {
      title: 'Données personnelles',
      description: 'Politique de collecte et traitement des données personnelles sur le site assmat.maine-et-loire.fr.'
    },
    component: () => import('../views/DonneesPersonnelles.vue')
  },
  {
    path: '/aide-a-la-navigation',
    name: 'aide-nav',
    meta: {
      title: 'Aide à la navigation',
      description: 'Aide à la navigation du site assmat.maine-et-loire.fr du Département de Maine-et-Loire.'
    },
    component: () => import('../views/Aide.vue')
  },
]