import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import headerGeneral from '@/components/headerGeneral.vue'
import footerGeneral from '@/components/footerGeneral.vue'
import buttonML from '@/components/Button.vue'
import alerts from '@/components/alerts.vue'

import helpers from '@/mixins/helpers'

import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.config.productionTip = false

Vue.component('headerGeneral', headerGeneral)
Vue.component('footerGeneral', footerGeneral)
Vue.component('buttonML', buttonML)
Vue.component('alerts', alerts)

Vue.mixin(helpers)

Vue.prototype.$http = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
