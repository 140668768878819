import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: "active",
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      setTimeout(() => {
        return window.scrollTo(0, 0)
      }, 50)
    }
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.name === 'accueil' ? to.meta.title : to.meta.title + ' - Assmat (49)'
  document.querySelector('meta[name="description"]').setAttribute('content',to.meta.description ? to.meta.description : 'Sites des assistants maternelle')
  next()
})

export default router
