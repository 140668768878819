export default {
  methods: {
    getLongDate: function(day) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      let date = new Date(day);
      return date.toLocaleDateString("fr",options)
    },
    isMobile: function() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      }
      return false
    },
    getMarkers: function (results) {
      let markers = [];
      
      results.forEach((m) => {
        let tmp = {};
        tmp.coord = { lat: m.adresse.latitude, lng: m.adresse.longitude };
        
        if(this.$route.name=='assmat') {
          tmp.content = `<p class='h3-like'>${m.nom} ${m.prenom}</p>
          ${m.adresse.numero||''} ${m.adresse.libelleAdresse||''} ${m.adresse.codePostal||''} ${m.adresse.commune||''}<br>
          <a href='https://www.google.fr/maps/search/?api=1&query=${m.adresse.latitude},${m.adresse.longitude}' target="_blank" title='Voir l’itinéraire vers l’assmat ${m.nom} ${m.prenom}'>Voir l’itinéraire</a>`;
        }
        else {
          tmp.content = `<p class='h3-like'>${m.nom} ${m.prenom}</p>
          ${m.adresse.numero||''} ${m.adresse.libelleAdresse||''} ${m.adresse.codePostal||''} ${m.adresse.commune||''}<br>
          <a href='/assmat/${m.id}' title='Voir la fiche de ${m.nom} ${m.prenom}'>Voir la fiche</a>`;
        }
        
        if(m.dispoActuelle.aucuneDispoActuelle && !m.disposFutures) tmp.status = 'aucuneDispo'
        else if(m.dispoActuelle.dispoPeriscolaire || m.dispoActuelle.dispoVacancesScolaires || m.dispoActuelle.nombreMoins1An || m.dispoActuelle.nombreMoins2Ans || m.dispoActuelle.nombreMoins3Ans) tmp.status = 'dispo'
        else if(m.disposFutures) tmp.status = 'disposFutures'
        else tmp.status = null
        
        markers.push(tmp);
      });
      
      return markers;
    },
    getWindowWidth: function() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }
  }
}