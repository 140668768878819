<template>
  
  <button type="button">{{ txt }}</button>
  
</template>

<script>
export default {
  name: 'Button',
  props: {
    class: String,
    txt: String
  }
}
</script>

<style scoped lang="scss">
.cta {
  .cta-primary {
    background-color: purple;
  }
}
</style>