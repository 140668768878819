function is_assmat_in_cart (state, assmat) {
  return state.assmats.findIndex((el) => el.id === assmat.id) > -1
}

const state = {
  assmats: []
};
const getters = {
  assmats: state => state.assmats,
  isAssmatExists: state => id => {
    if( state.assmats.findIndex((el) => el.id === id) != -1 ) return true
    return false
  }
};
const actions = {
  addAssmat({commit}, assmat) {
    commit('pushAssmatToCart', assmat)
  },
  removeAssmat({commit}, assmat) {
    commit('removeAssmatFromCart', assmat)
  },
  removeAllAssmats({commit}) {
    commit('flushCart')
  }
};
const mutations = {
  pushAssmatToCart(state, assmat) {
    if( !is_assmat_in_cart(state, assmat) ) {
      state.assmats.push(assmat)
    }
  },
  removeAssmatFromCart(state, assmat) {
    if( is_assmat_in_cart(state, assmat) ) {
      let toRemove = state.assmats.findIndex((el) => el.id === assmat.id)
      state.assmats.splice(toRemove,1)
    }
  },
  flushCart(state) {
    state.assmats = []
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};