<template>
  <div>
    <div v-for="alert in alerts" :key="alert.id" class="alert--warning">
      <a v-if="alert.url" :title="getTitle(alert.titre)" target="_blank" :href="alert.url">
        <div class="alert-date">
          <span><time :datetime="alert.date">{{getLongDate(alert.date)}}</time></span>
        </div>
        <div class="alert-content">
          <h2>{{alert.titre}}</h2>
          <p>{{alert.teaser}}</p>
        </div>
      </a>
      <div v-if="!alert.url.length">
        <div class="alert-date">
          <span><time :datetime="alert.date">{{getLongDate(alert.date)}}</time></span>
        </div>
        <div class="alert-content">
          <h2>{{alert.titre}}</h2>
          <p>{{alert.teaser}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'alerts',
  data() {
    return {
      alerts : [],
      error: false
    }
  },
  mounted() {
    this.getAlerts()
  },
  methods: {
    getAlerts: function () {
      this.$http.get('alertes')
      .then((result) => {
        this.alerts = result.data
        this.error = false
      })
      .catch((error) => {
        this.alerts = []
        this.error = true
      });
    },
    getTitle: function(title) {
      return `Alerte ${title} - Nouvelle fenêtre`
    }
  }
}
</script>

<style lang="scss" scoped>
div:first-of-type {
  margin-top: 1em;
  
  & > div > div:first-of-type {
    display: flex;
    
    &:hover > * {
      // Ne semble pas fonctionner alors que présent dans le navigateur
      text-decoration: none;
    }
  }
}
</style>